import {DateFactory} from '@app-shared/date/DateFactory';
import {Schedule} from '@models/schedule';
import {ScheduleStructure} from '@structures/schedule.structure';
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {DailySchedule} from "@models/DailySchedule";

export class ScheduleFactory extends AbstractPersistableFactory<any> {

	public dailyScheduleFactory: AbstractPersistableFactoryInterface;

	produce(scheduleStructure: ScheduleStructure) {

		return new Schedule().fromJson({
			date_begin: DateFactory.produceDate(scheduleStructure.date_begin).moment.toISOString(),
			date_end: DateFactory.produceDate(scheduleStructure.date_end).moment.toISOString(),
			is_locked: scheduleStructure.is_locked,
			id: scheduleStructure.id
		});
	}


	make(json: Schedule) {
		let schedule = new Schedule().fromJson(json as any);
		schedule.daily_schedules = this.dailyScheduleFactory
			.makeMany(json.daily_schedules)
			.sort((dsc1: DailySchedule, dsc2: DailySchedule) => dsc1.date.unix() - dsc2.date.unix());
		return schedule;
	}
}
