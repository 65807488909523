import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {ScheduleTime} from '@models/schedule-time';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	protected persists: boolean = false;
	protected httpHeaders = new HttpHeaders({'Content-type': 'application/json'});
	private _newPersistable = new Subject();

	constructor(protected http: HttpClient) {

	}

	protected apiUrl() {
		return environment.apiUrl;
	}

	protected postHttp<T>(endpoint: string, payload: any, persists = true): Observable<T> {
		return this.http.post<T>(this.apiUrl() + endpoint, payload, {headers: this.getAuthHeaders()})
			.pipe(map(value => this.mapPersistable<T>(value, persists)));
	}

	protected putHttp<T>(endpoint: string, payload: any, persists = true): Observable<T> {
		return this.http.put<T>(this.apiUrl() + endpoint, payload, {headers: this.getAuthHeaders()})
			.pipe(map(value => this.mapPersistable<T>(value, persists)));
	}

	protected getHttp<T>(endpoint: string, options?: HttpParams | {
		[param: string]: string | string[];
	}, persists = true): Observable<T> {
		this.removeEmptyParams(options);
		return this.http.get<T>(this.apiUrl() + endpoint, Object.assign({headers: this.getAuthHeaders()}, options))
			.pipe(map(value => this.mapPersistable<T>(value, persists)));
	}

	protected deleteHttp<T>(endpoint: string, persists = true): Observable<T> {
		return this.http.delete<T>(this.apiUrl() + endpoint, {headers: this.getAuthHeaders()})
			.pipe(map(value => this.mapPersistable<T>(value, persists)));
	}

	protected getAuthHeaders(): HttpHeaders {
		return this.httpHeaders
	}

	public map(json: any) {
		return json;
	}

	protected mapMany(json: any[]) {
		return json.map(value => this.map(value));
	}

	protected asMultipart(data: Object) {
		let formData = new FormData();
		Object.entries(data).forEach((entry) => {
			if (entry[1] !== null && entry[1] !== undefined)
				formData.append(entry[0], entry[1]);
		});
		return formData;
	}

	private mapPersistable<T>(json: T, persists = true) {
		if (this.persists && persists) {
			if (Array.isArray(json))
				return this.mapMany(json);
			else
				return this.map(json);
		}

		return json;
	}

	private removeEmptyParams(params) {
		if (params && params.params)
			Object.keys(params.params).forEach(key => params.params[key] === undefined ? delete params.params[key] : {})
	}

	private getToken() {
		return localStorage.getItem('api_token');
	}

	public onNewPersistable() {
		return this._newPersistable.asObservable()
	}

	protected newPersistable(x: ScheduleTime) {
		this._newPersistable.next(x)
	}
}
