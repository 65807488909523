import {Pipe, PipeTransform} from '@angular/core';
import {Moment} from "moment";

@Pipe({
	name: 'appDate'
})
export class DatePipe implements PipeTransform {

	transform(value: Moment, ...args: any[]): string {
		return value.format("MMMM D YYYY");
	}

}
