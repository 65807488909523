import {SkatingRing} from "@models/skating-ring";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";

export class SkatingRingFactory extends AbstractPersistableFactory<any> {
	unavailabilityFactory: AbstractPersistableFactoryInterface;

	make(json: any): any {
		let persistable = new SkatingRing().fromJson(json);
		if (json.unavailabilities)
			persistable.unavailabilities = this.unavailabilityFactory.makeMany(json.unavailabilities)
		return persistable;
	}
}
