import {Persistable} from '@models/persistable';
import {RoleStructure} from '@structures/role.structure';
import {User} from "@models/user";

export class Role extends Persistable {
	name: string;
	users: User[] = [];

    fromJson(persistable: RoleStructure): Role {
        this.name = persistable.name;
        this.id = persistable.id;
        return this;
    }

    json(): string {
        return JSON.stringify({role: this.structure});
    }

    get structure(): RoleStructure {
        return {
            id: this.id,
            name: this.name
        };
    };
}
