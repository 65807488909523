import {StatusIconComponent} from '@app-shared/materials/status-icon/status-icon.component';
import {SnackbarComponent} from '@app-shared/materials/snackbar/snackbar.component';
import {DestroyConfirmationComponent} from '@app-shared/materials/destroy-confirmation/destroy-confirmation.component';
import {SafeHtmlPipe} from '@app-shared/materials/safe-html/safe-html.pipe';
import {DashboardButtonComponent} from '@app-shared/materials/dashboard-button/dashboard-button.component';
import {ScheduleSelectorComponent} from '@app-shared/materials/schedule-selector/schedule-selector.component';
import {UserTypeSelectorComponent} from '@app-shared/materials/user-type-selector/user-type-selector.component';
import {AppTitleComponent} from '@app-shared/materials/app-title/app-title.component';
import {ButtonsComponent} from '@app-shared/materials/buttons/buttons.component';
import {FooterComponent} from '@app-shared/materials/footer/footer.component';
import {ContainerComponent} from '@app-shared/materials/container/container.component';
import {HeaderComponent} from '@app-shared/materials/header/header.component';
import {ContentComponent} from '@app-shared/materials/content/content.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomDirectivesModule} from '@app-shared/materials/custom-directives/custom-directives.module';
import {HyperlinkComponent} from '@app-shared/materials/hyperlink/hyperlink.component';
import {BoardButtonComponent} from '@app-shared/materials/board-button/board-button.component';
import {ScrollableTableDirective} from '@app-shared/materials/scrollable-table/scrollable-table.directive';
import {OverflowHiddenDirective} from '@app-shared/materials/overflow-hidden/overflow-hidden.directive';
import {DisplayedTimeModule} from "@modules/time/displayed-time/displayed-time.module";

const declarations = [
    StatusIconComponent,
    SnackbarComponent,
    DestroyConfirmationComponent,
    SafeHtmlPipe,
    DashboardButtonComponent,
    ScheduleSelectorComponent,
    UserTypeSelectorComponent,
    AppTitleComponent,
    ButtonsComponent,
    FooterComponent,
    ContainerComponent,
    HeaderComponent,
    ContentComponent,
    HyperlinkComponent,
    BoardButtonComponent,
    ScrollableTableDirective,
    OverflowHiddenDirective

];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatSnackBarModule,
		MatButtonModule,
		MatCardModule,
		MatSelectModule,
		FormsModule,
		MatToolbarModule,
		CustomDirectivesModule,
		DisplayedTimeModule
	],
	declarations: declarations,
	exports: declarations
})
export class CustomComponentsModule {

}
