import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class KeyStrokeService {
	private deleteSubject = new Subject<KeyboardEvent>();
	isShiftPressed: boolean = false;
	isControlPressed: boolean = false;

	constructor() {
	}

	keyUp(event: KeyboardEvent) {
		if (event.code == "Backspace" || event.code == "Delete")
			this.deleteSubject.next(event);
		this.isShiftPressed = false;
		this.isControlPressed = false;
	}

	onDeleteStroke() {
		return this.deleteSubject.asObservable()
	}

	keyDown(event: KeyboardEvent) {
		if (event.code == "ShiftRight" || event.code == "ShiftLeft")
			this.isShiftPressed = true
		if (event.code == "MetaRight" || event.code == "MetaLeft")
			this.isControlPressed = true
	}
}
