import {Persistable} from '@models/persistable';
import {RequiredTimeStructure} from '@structures/RequiredTime.structure';
import {Frequency} from '@structures/repetition.structure';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Teacher} from '@models/teacher';
import {Contract} from "@models/contract";

export class RequiredTime extends Persistable {
	private _frequency: Frequency;
	private _repeatUntil: Moment;
	private _startsAt: Moment;
	private _requiredTimeMinutes: number;
	private _teacherId: number;
	private _teacher: Teacher;
	contract: Contract;
	contract_id: number;

	static fromJson(object: RequiredTimeStructure) {
	}

	fromJson(persistable: RequiredTimeStructure): RequiredTime {
		this.contract_id = persistable.contract_id;
		this.id = persistable.id;
		this._teacherId = persistable.teacher_id;
		this._requiredTimeMinutes = persistable.required_time_minutes;
		this._startsAt = moment.utc(persistable.starts_at);
		this._repeatUntil = moment.utc(persistable.repeat_until);
		this._frequency = persistable.frequency;
		return this;
	}

    json(): string {
        return JSON.stringify({required_time: this.structure});
    }

    get teacher(): Teacher {
        return this._teacher;
    }

    set teacher(value: Teacher) {
        this._teacher = value;
    }

    get frequency(): Frequency {
        return this._frequency;
    }

    set frequency(value: Frequency) {
        this._frequency = value;
    }

    get repeatUntil(): Moment {
        return this._repeatUntil;
    }

    set repeatUntil(value: Moment) {
        this._repeatUntil = value;
    }

    get startsAt(): Moment {
        return this._startsAt;
    }

    set startsAt(value: Moment) {
        this._startsAt = value;
    }

    get requiredTimeMinutes(): number {
        return this._requiredTimeMinutes;
    }

    set requiredTimeMinutes(value: number) {
        this._requiredTimeMinutes = value;
    }

    get teacherId(): number {
        return this._teacherId;
    }

    set teacherId(value: number) {
        this._teacherId = value;
    }

    get structure(): RequiredTimeStructure {
        return {
	        id: this.id,
	        frequency: this.frequency,
	        repeat_until: this.repeatUntil.toISOString(),
	        required_time_minutes: this.requiredTimeMinutes,
	        starts_at: this.startsAt.toISOString(),
	        contract_id: this.contract_id,
	        teacher_id: this.teacherId
        };
    }
}
