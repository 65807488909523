import {NotificationSubscriptionStructure} from '@structures/notification-subscription.structure';
import {Persistable} from '@models/persistable';

export class NotificationSubscription extends Persistable {
    structure: NotificationSubscriptionStructure;
    private _userId: number;
    private _notificationTypeId: number;

    static fromJson(notificationSubscriptionStructure: NotificationSubscriptionStructure): NotificationSubscription {
        return new NotificationSubscription().fromJson(notificationSubscriptionStructure);
    }

    json(): string {
        return JSON.stringify({
            notification_subscription: {
                id: this.id,
                user_id: this.userId,
                notification_type_id: this.notificationTypeId,
            }
        });
    }

    fromJson(persistable: NotificationSubscriptionStructure): NotificationSubscription {
        this.userId = persistable.user_id;
        this.notificationTypeId = persistable.notification_type_id;
        this.id = persistable.id;
        if (persistable.notification_type)
            this.notificationTypeId = persistable.notification_type.id;
        if (persistable.user)
            this.userId = persistable.user.id;
        return this;
    }

    get notificationTypeId(): number {
        return this._notificationTypeId;
    }

    set notificationTypeId(value: number) {
        this._notificationTypeId = value;
    }

    get userId(): number {
        return this._userId;
    }

    set userId(value: number) {
        this._userId = value;
    }
}
