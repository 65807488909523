import {Component, Input, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
    private _message: string;
    private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

    constructor(private snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    listenToSnackbarEvents() {
        this.snackBarRef.onAction().subscribe(() => {
            this.dismiss();
        });
    }

    dismiss() {
        this.snackBar.dismiss();
    }

    @Input('message') set message(value: string) {
        if (value && this._message != value) {
            this._message = value;
            this.snackBarRef = this.snackBar.open(value, 'Ok', {
                duration: 5000
            });
            this.listenToSnackbarEvents();
        }
        this._message = value;
    }

}
