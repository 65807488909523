import {SkatingRingStructure} from '@structures/skating-ring.structure';
import {Persistable} from '@models/persistable';
import {SkatingRingInterface} from "@models/interfaces/SkatingRingInterface";

export class SkatingRing extends Persistable implements SkatingRingInterface {
	private _name: string;
	private _maxGroups: number = 5;
	private _priority: number;
	locationId: number;
	unavailabilities: any[] = [];
	isFolded: boolean = false;

	json() {
		let json = {
			skating_ring: this.structure
		};
		return JSON.stringify(json);
	}

	fromJson(persistable: SkatingRingStructure): SkatingRing {
		this.name = persistable.name;
		this.id = persistable.id;
		this.maxGroups = persistable.max_groups;
		this.priority = persistable.priority;
		this.locationId = persistable.location_id;
		return this;
	}

	get priority(): number {
		return this._priority;
	}

    set priority(value: number) {
        this._priority = value;
    }

    get structure(): SkatingRingStructure {
        return {
            name: this.name,
            max_groups: this.maxGroups,
            id: this.id,
            priority: this.priority,
	        location_id: this.locationId
        };
    }

    get maxGroups(): number {
        return this._maxGroups;
    }

    set maxGroups(value: number) {
        this._maxGroups = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
}
