import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SchoolService} from '@modules/school/school.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SchoolGuard implements CanActivateChild {

    constructor(private schoolService: SchoolService,
                private router: Router) {
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let component = next.component;
        let snapshot = next;
        let code = next.params.school_code;
        let i = 0;
        while (component != null && i++ < 10) {
            code = snapshot.params.school_code;
            if (code != null)
                break;
            snapshot = next.parent;
            component = snapshot.component;
        }

        if (this.schoolService.current_school && this.schoolService.current_school.code == code)
            return true;

        return this.schoolService.getAll().pipe(
            map(schools => {
                const currentSchool = schools.find(school => school.code == code);
                if (currentSchool) {
                    this.schoolService.current_school = currentSchool;
                    return true;
                }
                this.router.navigate(['/school/select']);
                return false;
            })
        );
    }

}
