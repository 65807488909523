import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";


@NgModule({
	declarations: [],
	imports: [
		CommonModule
	]
})
export class AppIconsModule {
	icons = [
		{name: 'skate', filename: 'skate'},
		{name: 'user', filename: 'user'},
		{name: 'link', filename: 'external-link-alt-solid'},
		{name: 'contract', filename: 'contract'},
		{name: 'zamboni', filename: 'zamboni'},
		{name: 'education', filename: 'education'},
	]

	constructor(
		private domSanitizer: DomSanitizer,
		private matIconRegistry: MatIconRegistry) {
		this.icons.forEach(icon => {
			this.matIconRegistry.addSvgIcon(
				icon.name,
				this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/' + icon.filename + '.svg')
			);
		})
	}
}
