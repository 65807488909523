import * as moment from 'moment';
import {Moment} from 'moment';
import {ScheduleStructure} from '@structures/schedule.structure';
import {Persistable} from '@models/persistable';
import {DailySchedule} from "@models/DailySchedule";

export class Schedule extends Persistable {
	isLocked: boolean;
	public date_begin: Moment;
	public date_end: Moment;
	is_published: boolean;
	daily_schedules: DailySchedule[];

	json(): string {
		return JSON.stringify(this.structure);
	}

	fromJson(persistable: ScheduleStructure): Schedule {
		this.date_begin = moment.utc(persistable.date_begin);
		this.date_end = moment.utc(persistable.date_end);
		if (!persistable.date_begin && !persistable.date_end) {
			this.date_begin = moment.utc();
			this.date_end = moment.utc();
		}
		this.id = persistable.id;
		this.isLocked = persistable.is_locked;
		return this;
	}

	overlaps(date: Moment) {
		return date.isBetween(this.date_begin, this.date_end, 'day', '[]')
	}

	get dayDuration() {
		return Math.abs(moment.duration(this.date_begin.diff(this.date_end)).asDays()) + 1;
	}

	get structure(): ScheduleStructure {
		return {
			date_begin: this.date_begin.toISOString(),
			date_end: this.date_end.toISOString(),
			is_locked: this.isLocked,
			id: this.id
		};
    }
}
