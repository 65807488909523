import {StudentStructure} from "@structures/student.structure";
import {Student} from "@models/student";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {Role} from "@models/role";

export class StudentFactory extends AbstractPersistableFactory<any> {
	contractFactory: AbstractPersistableFactory<any>;

	make(json: StudentStructure): Student {
		let student = new Student().fromJson(json)
		if (json.partner)
			student.partner = this.make(json.partner as StudentStructure);
		if (json.assigned_group)
			student.assignedGroup = (new Role()).fromJson(json.assigned_group);
		if (json.contracts)
			student.contracts = this.contractFactory.makeMany(json.contracts);
		return student;
	}
}
