import {Persistable} from '@models/persistable';
import {ContractStructure} from '@structures/contract.structure';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ContractInterface} from "@models/interfaces/ContractInterface";

export class Contract extends Persistable implements ContractInterface {
	order_number: number = 0;
	private _validUntil: Moment;
	private _createdAt: Moment;
	private _studentId: number;
	private _dailyRequiredTime: number;
	private _startsAt: Moment;
	private _additionalDetails: string;
	private _isCurrent: boolean;
	private _partnerId: number;
	partner: any;
	unavailabilities: Persistable[] = [];
	private _nickname: string;
	private _student: Persistable;
	schedule_times: any[] = [];

	constructor() {
		super();
		this.validUntil = this.todayAtMidnight();
		this.startsAt = this.todayAtMidnight();
	}

	fromJson(json: ContractStructure): Contract {
		this.id = json.id;
		this.validUntil = moment.utc(json.valid_until);
		this.startsAt = moment.utc(json.starts_at);
		this.additionalDetails = json.additional_details;
		this.createdAt = moment.utc(json.created_at);
		this.studentId = json.student_id;
		this.dailyRequiredTime = json.daily_required_time;
		this.partnerId = json.partner_id;
		this.nickname = json.nickname;
		if (json.order_number)
			this.order_number = json.order_number;
		return this;
	}

	json(): string {
		return JSON.stringify({contract: this.structure});
	}

	belongsToStudent(id: number) {
		return this.studentId == id || this.partnerId == id;
	}

	private todayAtMidnight(): Moment {
		let startDate = moment.utc();
		startDate.set('hour', 0);
		startDate.set('minute', 0);
		startDate.set('second', 0);
		startDate.set('millisecond', 0);
		return startDate;
	}

	private setIsCurrent() {
		if (this.startsAt && this.validUntil)
			this._isCurrent = this.todayAtMidnight().isSameOrBefore(this.validUntil) &&
				this.todayAtMidnight().isSameOrAfter(this.startsAt);
	}

	get nickname(): string {
		return this._nickname;
	}

	set nickname(value: string) {
		this._nickname = value;
	}

	get student(): Persistable {
		return this._student;
	}

	set student(value: Persistable) {
		this._student = value;
	}

	get partnerId(): number {
		if (this.partner)
			return this.partner.id;
		return this._partnerId;
	}

	set partnerId(value: number) {
		this._partnerId = value;
	}

	get isCurrent(): boolean {
		return this._isCurrent;
	}

	get additionalDetails(): string {
		return this._additionalDetails;
	}

	set additionalDetails(value: string) {
		this._additionalDetails = value;
	}

	get startsAt(): moment.Moment {
		return this._startsAt;
	}

	set startsAt(value: moment.Moment) {
		this.setIsCurrent();
		this._startsAt = value;
	}

	get createdAt(): moment.Moment {
		return this._createdAt;
	}

	set createdAt(value: moment.Moment) {
		this._createdAt = value;
	}

	get studentId(): number {
		if (this.student)
			return this.student.id;
		return this._studentId;
	}

	set studentId(value: number) {
		this._studentId = value;
	}

	get dailyRequiredTime(): number {
		return this._dailyRequiredTime;
	}

	set dailyRequiredTime(value: number) {
		this._dailyRequiredTime = value;
	}

	get validUntil(): Moment {
		return this._validUntil;
	}

	set validUntil(value: Moment) {
		this.setIsCurrent();
		this._validUntil = value;
	}

	get structure(): ContractStructure {
		return {
			additional_details: this.additionalDetails,
			starts_at: this.startsAt.toISOString(),
			created_at: this.createdAt ? this._createdAt.toISOString() : null,
			daily_required_time: this.dailyRequiredTime,
			student_id: this.studentId,
			nickname: this.nickname,
			id: this.id,
			valid_until: this.validUntil.toISOString(),
			partner_id: this.partnerId
		};
	}

	get name(): string {
		return this.nickname
	}
}
