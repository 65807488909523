import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {User} from '../models/user';
import {environment} from '@environments/environment';
import {map, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {UsersStructure} from '@structures/users.structure';
import {StudentStructure} from '@structures/student.structure';
import {UserFactory} from '@factories/user.factory';
import {SolverService} from "@modules/solver/solver.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PersistableFactoryFactory} from "@factories/PersistableFactoryFactory";

@Injectable({
	providedIn: 'root'
})
export class SessionsService {
	current_user: User;
	private userFactory: UserFactory;

	constructor(private http: HttpClient,
	            private solverService: SolverService,
	            private matSnackBar: MatSnackBar,
	            persistableFactoryFactory: PersistableFactoryFactory) {
		this.userFactory = persistableFactoryFactory.userFactory
	}

    login(users: LoginPayload): Observable<User> {
        sessionStorage.removeItem('token');
        let headers = this.headersWithoutAutorization;
        (headers as any).observe = 'response';
	    return this.http.post<HttpResponse<User>>(environment.apiUrl + '/users/sign_in', {user: users}, headers).pipe(
		    tap(response => {
			    this.current_user = this.userFactory.make(response.body as unknown as StudentStructure);
			    this.storeToken(response.headers.get('authorization'));
		    }),
		    tap(() => this.showNotificationOnSolverCompletion()),
		    map((response) => response.body)
	    );
    }

	private showNotificationOnSolverCompletion() {
		this.solverService.onSolvingCompletion().subscribe(
			data => this.matSnackBar.open('Solving completed', 'Ok!')
		)
	}

	logout(): Observable<any> {
		let fakeObservable = new Subject();
		this.current_user = null;
		sessionStorage.removeItem('token');
		fakeObservable.next(2);
		return (fakeObservable).asObservable();
		//@TODO fix delete session request
		// return this.http.delete("/users/sign_out").pipe(tap(
		// 	() => this.user = null
        // ))
    }

    isLoggedIn(): Observable<boolean> {
        return this.http.get<boolean>(environment.apiUrl + '/users/is_logged_in', this.headers);
    }

    public storeToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    getCurrentUser(): Observable<User> {
        return this.http.get<UsersStructure>(environment.apiUrl + '/users/current_user')
            .pipe(
	            map((user) => this.userFactory.make(user)),
	            tap((user: User) => this.current_user = user)
            );
    }

    get headers() {
        let headers = this.headersWithoutAutorization;
        sessionStorage.getItem('token') ? headers.headers = headers.headers.append('authorization', sessionStorage.getItem('token')) : null;
        return headers;
    }

    get token() {
        return sessionStorage.getItem('token');
    }

    private get headersWithoutAutorization() {
        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", 'application/json');
        return {headers: headers};
    }
}

export interface LoginPayload {
    email: string,
    password: string
}
