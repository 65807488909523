import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-board-button',
    templateUrl: './board-button.component.html',
    styleUrls: ['./board-button.component.css']
})
export class BoardButtonComponent implements OnInit {

    @Input() icon: string = '';
    @Input() svgIcon: string;

    constructor() {
    }

    ngOnInit() {
    }

}
