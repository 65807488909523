import {Compiler, Injectable, Injector, Type} from "@angular/core";
import {ChildrenOutletContexts, NavigationExtras, Route, Router, Routes, UrlSerializer, UrlTree} from "@angular/router";
import {SchoolService} from '@modules/school/school.service';
import {Location as Location_2, Location} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AppRouterService extends Router {
    constructor(rootComponentType: Type<any> | null,
                urlSerializer: UrlSerializer,
                rootContexts: ChildrenOutletContexts,
                location: Location_2,
                injector: Injector,
                compiler: Compiler,
                config: Routes,
                private schoolService: SchoolService) {
        super();

    }

    createUrlTree(commands: any[], navigationExtras?: NavigationExtras): UrlTree {
        if (commands[0] && typeof commands[0] == 'string' && commands[0].startsWith('/admin') && this.schoolService.current_school)
            commands[0] = '/' + this.schoolService.current_school.code + commands[0];
        return super.createUrlTree(commands, navigationExtras);
    }

    navigateByUrl(url: string, extras?: NavigationExtras): Promise<boolean> {
        if (url && typeof url == 'string' && url.startsWith('/admin') && this.schoolService.current_school)
            url = '/' + this.schoolService.current_school.code + url;
        return super.navigateByUrl(url, extras);
    }
}


export function routerFactory(rootComponentType: Type<any>,
                              urlSerializer: UrlSerializer,
                              rootContexts: ChildrenOutletContexts,
                              location: Location,
                              injector: Injector,
                              compiler: Compiler,
                              config: Route[][],
                              schoolService: SchoolService): Router {
    return new AppRouterService(
        rootComponentType,
        urlSerializer,
        rootContexts,
        location,
        injector,
        compiler,
        config[0] as Routes,
        schoolService
    );
}
