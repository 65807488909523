import {Persistable, Persists} from "@models/new-persist/Persistable";
import {Schedule} from "@models/schedule";
import {ScheduleTime} from "@models/schedule-time";
import * as moment from "moment";
import {Moment} from "moment";
import {Unavailability} from "@models/unavailability";

const DEFINITION = [
	'status',
	'schedule_id',
];

export class DailySchedule extends Persistable<DailySchedule> implements Persists {
	status: string;
	schedule: Schedule;
	schedule_times: ScheduleTime[] = [];
	unavailabilities: Unavailability[] = [];
	date: Moment;
	displayDate: string;
	schedule_id: number;

	getDefinition(): string[] {
		return DEFINITION;
	}

	protected afterMap(json: DailySchedule) {
		this.date = moment.utc(json.date);
		this.displayDate = this.date.format("dddd MMMM DD YYYY");
	}

	get is_weekday(): boolean {
		return this.date.weekday() != 0 && this.date.weekday() != 6;
	}

	get is_weekend(): boolean {
		return !this.is_weekday;
	}
}

export enum DailyScheduleRelations {
	SCHEDULE_TIMES = "schedule_times",
	UNAVAILABILITIES = "unavailabilities",
}
