import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LaravelSessionService} from '@services/laravel-session.service';

@Injectable({
    providedIn: 'root'
})
export class LaravelSessionGuard implements CanActivate {
    private isLoggedInSubject: Subject<boolean> = new ReplaySubject(1);

    constructor(private sessionService: LaravelSessionService,
                private router: Router) {
        this.sessionService.isLoggedIn.subscribe(
            isLoggedIn => this.isLoggedInSubject.next(isLoggedIn)
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.sessionService.hasSessionInfoStored) {
            console.log('no session info stored');
            this.setRedirectUrl(next, state);
            this.router.navigate([this.sessionService.login_path]);
            return false;
        }
        return this.isLoggedInSubject.pipe(
            tap(isLoggedIn => {
                if (!isLoggedIn) {
                    console.log("not logged in, redirecting...")
                    this.setRedirectUrl(next, state)
                    this.router.navigate([this.sessionService.login_path])
                }
            })
        );
    }

    private setRedirectUrl(next: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        if(state.url != this.sessionService.login_path && !this.sessionService.lastVisitedRoute)
            this.sessionService.lastVisitedRoute = next
    }
}
