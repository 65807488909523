import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;
        if (sessionStorage.getItem('token') && req.headers.get('Authorization') == null) {
            headers = req.headers.set('Authorization', sessionStorage.getItem('token'));
        }
        const authReq = req.clone({
            headers: headers
        });
        return next.handle(authReq);
    }
}
