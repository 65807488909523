import {Moment} from 'moment';
import {DateTime} from 'luxon';

export class FormatableDate {
    private readonly _moment: Moment;

    constructor(date: Moment) {
        date = date.utc();
        this._moment = date;
    }

    differenceInMinutes(formatableDate: FormatableDate) {
        return Math.abs(this.moment.diff(formatableDate.moment, 'minute'));
    }

    differenceInWeeks(formatableDate: FormatableDate) {
        return Math.ceil(Math.abs(this.moment.diff(formatableDate.moment, 'weeks', true)));
    }

    transfertTimesToWeek(currentWeek: FormatableDate) {
        let weeks = this.differenceInWeeks(currentWeek);
        this.moment.utc().add(weeks, 'weeks');
    }

    get moment(): Moment {
        return this._moment;
    }

    get ISOString() {
        return this.moment.toISOString(false);
    }

    get friendlyDate() {
        return this.moment.format('MMMM DD YYYY');
    }

    get friendlyDateTime() {
        return this.moment.format('dddd, MMMM Do YYYY, hh:mm:ss a');
    }

    get slashedDate() {
        return this.moment.format('DD/MM/YYYY');
    }

    get time() {
        return this.moment.format('hh:mm a');
    }

    get year() {
        return this.moment.format('YYYY');
    }

    get day() {
        return this.moment.format('DD');
    }

    get month() {
        return this.moment.format('MMMM');
    }

    get dashedDate() {
        return this.moment.format('DD-MM-YYYY');
    }

    get dayMinutes() {
        return this.moment.hours() * 60 + this.moment.minutes();
    }

    get timeWithDayMoment() {
        return this.moment.format('hh:mm a');
    }

    get luxon() {
        return DateTime.fromISO(this.ISOString);
    }
}
