import {Injector, NgModule, NgModuleRef} from '@angular/core';
import {CommonModule} from '@angular/common';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class InjectorModule {
    public static injector: Injector;

    constructor(ref: NgModuleRef<InjectorModule>) {
        InjectorModule.injector = ref.injector;
    }
}
