import {DailySchedule} from "@models/DailySchedule";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";

export class DailyScheduleFactory extends AbstractPersistableFactory<DailySchedule> {
	scheduleFactory: AbstractPersistableFactoryInterface;
	scheduleTimeFactory: AbstractPersistableFactoryInterface;

	make(json: DailySchedule) {
		let dsc = new DailySchedule().map(json) as DailySchedule;
		if (json.schedule)
			dsc.schedule = this.scheduleFactory.make(json.schedule);
		if (json.schedule_times)
			dsc.schedule_times = this.scheduleTimeFactory.makeMany(json.schedule_times);
		return dsc;
	}
}

