import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialsModule} from './shared/materials/materials.module';
import {InterceptorsModule} from '@interceptors/interceptors.module';
import {AlveoRouteProviderModule} from '@app-shared/routes/alveo-route-provider.module';
import {AppIconsModule} from "./app-icons.module";
import {InjectorModule} from "./Injector.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
	    BrowserModule,
	    InterceptorsModule,
	    AppRoutingModule,
	    BrowserAnimationsModule,
	    HttpClientModule,
	    FormsModule,
	    ReactiveFormsModule,
	    MaterialsModule,
	    AlveoRouteProviderModule,
	    AppIconsModule,
		InjectorModule
    ],
	bootstrap: [AppComponent],
	exports: [
		BrowserAnimationsModule
	]

})
export class AppModule {
}
