import {UsersStructure} from '@structures/users.structure';
import {NotificationSubscription} from '@models/notifications/notification-subscription';
import {Persistable} from '@models/persistable';
import {Student} from '@models/student';

export abstract class User extends Persistable {
    private _isDeleted: boolean;
    private _email: string = '';
    private _password: string = '';
    private _first_name: string;
    private _last_name: string;
    private _admin: boolean;
	private _schedulable_entity_id: number;
	private _notificationSubscriptions: NotificationSubscription[] = [];
	private _isDisplayed: boolean = false;
	private _code: string;
	private _colorCode: string = '#a1a1a1';
	contrast_color_code: string = '#ffffff';
	private _isTeacher: boolean;
	private _isStudent: boolean;
	private _partner: Student;
	private _passwordExpired: boolean;
	short_name: string;
    school_id: number;
    api_token: string;

	fromJson(userStructure: UsersStructure) {
		this.first_name = userStructure.first_name;
        this.last_name = userStructure.last_name;
        this.school_id = userStructure.school_id;
        this.schedulable_entity_id = userStructure.schedulable_entity_id;
		this._admin = userStructure.admin;
		this.email = userStructure.email;
		this.password = userStructure.password;
		this.id = userStructure.id;
		this.colorCode = userStructure.color_code;
		this.isDeleted = userStructure.is_deleted;
		this.isTeacher = userStructure.is_teacher;
        this.isStudent = userStructure.is_student;
        this.api_token = userStructure.api_token;
        this.contrast_color_code = userStructure.contrast_color_code;
		this.short_name = this.first_name.charAt(0) + ' ' + this.last_name.charAt(0)
		this.passwordExpired = userStructure.password_expired || false;
		if (userStructure.notification_subscriptions)
			this.notificationSubscriptions = userStructure.notification_subscriptions.map(sub => NotificationSubscription.fromJson(sub));
		return this;
	}

    public json() {
        return JSON.stringify({user: this.structure});
    }

    abstract apiPayloadStructure();

    isSubscribed(subscriptionId: number) {
        return this.notificationSubscriptions
            && this.notificationSubscriptions.filter(value => value.notificationTypeId == subscriptionId).length > 0;
    }

    protected userStructure(): UsersStructure {
        let users: UsersStructure = {
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            contrast_color_code: this.contrast_color_code,
            code: this.code,
            admin: this.admin,
            color_code: this._colorCode,
            id: this.id,
            is_student: this.isStudent,
            is_teacher: this.isTeacher,
            school_id: this.school_id
        };

        this.password ? (users as any).password = this.password : null;
        return users;
    }

    get passwordExpired(): boolean {
        return this._passwordExpired;
    }

    set passwordExpired(value: boolean) {
        this._passwordExpired = value;
    }

    get userType(): 'student' | 'teacher' {
        if (this.isStudent)
            return 'student';
        if (this.isTeacher)
            return 'teacher';
        return 'student';
    }

    get partner(): Student {
        return this._partner;
    }

    set partner(value: Student) {
        this._partner = value;
    }

    get isStudent(): boolean {
        return this._isStudent;
    }

    set isStudent(value: boolean) {
        this._isStudent = value;
    }

    get isTeacher(): boolean {
        return this._isTeacher;
    }

    set isTeacher(value: boolean) {
        this._isTeacher = value;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    get colorCode(): string {
        return this._colorCode;
    }

    set colorCode(value: string) {
        this._colorCode = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value.trim();
    }

    get isDisplayed(): boolean {
        return this._isDisplayed;
    }

    set isDisplayed(value: boolean) {
        this._isDisplayed = value;
    }

    get notificationSubscriptions(): NotificationSubscription[] {
        return this._notificationSubscriptions;
    }

    set notificationSubscriptions(value: NotificationSubscription[]) {
        this._notificationSubscriptions = value;
    }

    get schedulable_entity_id(): number {
        return this._schedulable_entity_id;
    }

    set schedulable_entity_id(value: number) {
        this._schedulable_entity_id = value;
    }

    get admin(): boolean {
        return this._admin;
    }

    get display_name(): string {
        return this.first_name + ' ' + this.last_name;
    }

    get first_name(): string {
        return this._first_name;
    }

    set first_name(value: string) {
        this._first_name = value;
    }

    get last_name(): string {
        return this._last_name;
    }

    set last_name(value: string) {
        this._last_name = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get password(): string {
        return this._password;
    }

	set password(value: string) {
		this._password = value;
	}

	get partnerId(): number {
		if (this.partner)
			return this.partner.id;
	}

	abstract belongsToRole(roleId: number): boolean;

	get name() {
		return this.first_name + " " + this.last_name
	}
}
