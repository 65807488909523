import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import {UserService} from '@services/user.service';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {User} from '@models/user';
import {LaravelApiService} from '@services/LaravelApiService';
import {Optional} from 'typescript-optional';
import {environment} from '@environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LaravelSessionService extends LaravelApiService {
    private currentUser: Subject<User | 'loading'> = new BehaviorSubject<User | 'loading'>('loading');
    private _isLoggedIn: boolean = false;
    lastVisitedRoute: ActivatedRouteSnapshot;
    login_path = '/login';

    constructor(private userService: UserService,
                private router: Router,
                http: HttpClient) {
        super(http);
        if (localStorage.getItem(this.LARAVEL_API_TOKEN) && localStorage.getItem(this.LARAVEL_CURRENT_USER_ID))
            userService.getUser(+localStorage.getItem(this.LARAVEL_CURRENT_USER_ID)).pipe(
                catchError(() => {
                    this.clearStorage();
                    return of(null);
                })
            ).subscribe(
                (user: User) => this.onUserFetch(user)
            );
    }

    clearStorage() {
        localStorage.removeItem(this.LARAVEL_CURRENT_USER_ID);
        localStorage.removeItem(this.LARAVEL_API_TOKEN);
    }

    getCurrentUser(): Observable<Optional<User>> {
        return this.currentUser.asObservable().pipe(
            filter(value => value != "loading"),
            map(value => {
                return Optional.ofNullable(value) as Optional<User>
            })
        )
    }

    login(authInfo: { email: string, password: string }): Observable<User> {
        return this.putHttp<User>('/sessions', authInfo).pipe(
            map(user => this.map(user)),
            tap(user => this.setLoggedInUser(user)),
            catchError((err, caught) => {
                console.log("Server returned no user connected, with error.")
                console.error(err)
                this.logOut();
                return throwError(err)
            })
        )
    }

    logOut() {
        this.currentUser.next(null);
        this.clearStorage();
        this.router.navigate([this.login_path])
    }

    setLoggedInUser(user: User) {
        localStorage.setItem(this.LARAVEL_API_TOKEN, (user as any).api_token);
        localStorage.setItem(this.LARAVEL_CURRENT_USER_ID, user.id + '');
        this.onUserFetch(user);
    }

    public map(json: any): any {
        return this.userService.map(json);
    }

    protected apiUrl() {
        return environment.laravelApiUrl + '/auth';
    }

    private onUserFetch(user: User) {
        this.currentUser.next(user);
        this._isLoggedIn = true;
    }

    get isLoggedIn(): Observable<boolean> {
        return this.getCurrentUser().pipe(
            tap(user => {
                if (user.isPresent())
                    this._isLoggedIn = true;
                else
                    console.log("Observable returned no user connected")
            }),
            map(value => value.isPresent())
        );
    }

    get hasSessionInfoStored() {
        return localStorage.getItem(this.LARAVEL_API_TOKEN) && localStorage.getItem(this.LARAVEL_CURRENT_USER_ID);
    }
}

export interface LoginPayload {
    email: string,
    password: string
}
