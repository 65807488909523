import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;
        if (!headers.has('Content-type')) {
            headers = req.headers.set('Content-type', 'application/json');
        }
        const authReq = req.clone({
            headers: headers
        });
        return next.handle(authReq);
    }
}
