import {Persistable} from '@models/persistable';
import {Frequency, RepetitionStructure} from '@structures/repetition.structure';
import * as moment from 'moment';
import {Moment} from 'moment';
import {RepetitionInterface} from '@models/interfaces/RepetitionInterface';
import {Unavailability} from '@models/unavailability';

export class Repetition extends Persistable implements RepetitionInterface {
    private _repeatUntil: Moment;
    private _frequency: Frequency;
    private _main_unavailability: any;

    fromJson(persistable: RepetitionStructure): Repetition {
        this.id = persistable.id;
        this.repeatUntil = moment.utc(persistable.repeat_until);
        this.frequency = persistable.frequency;
        this.main_unavailability = (new Unavailability()).map(persistable.main_unavailability);
        return this;
    }

    json(): string {
        return JSON.stringify({repetition: this.structure});
    }

    get main_unavailability(): Unavailability {
        return this._main_unavailability;
    }

    set main_unavailability(value: Unavailability) {
        this._main_unavailability = value;
    }

    get repeatUntil(): Moment {
        return this._repeatUntil;
    }

    set repeatUntil(value: Moment) {
        this._repeatUntil = value;
    }

    get frequency(): Frequency {
        return this._frequency;
    }

    set frequency(value: Frequency) {
        this._frequency = value;
    }

    get structure(): RepetitionStructure {
        return {
            id: 0, frequency: this._frequency, repeat_until: this._repeatUntil.toISOString()
        };
    }

}
