import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {SessionsService} from '@services/sessions.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {LaravelSessionService} from '@services/laravel-session.service';
import {Optional} from 'typescript-optional';
import {User} from '@models/user';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

    constructor(private sessionService: SessionsService,
                private laravelSessionService: LaravelSessionService,
                private router: Router) {
    }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.sessionService.current_user && this.sessionService.token) {
            return true;
        }
        return this.checkIfLoggedIn();
    }

    private checkIfLoggedIn(): Observable<boolean> {
        return this.sessionService.isLoggedIn().pipe(
            switchMap((res) => {
                if (res)
                    return this.laravelSessionService.getCurrentUser();
                throwError('login failed');
            }),
            switchMap((res: Optional<User>) => {
                if (res && res.isPresent())
                    return this.sessionService.getCurrentUser().pipe(
                        map(() => true)
                    );
                throwError('login failed for laravel');
            }),
            catchError(error => {
                this.router.navigate(['/login']);
                return of(false);
            })
        );
    }
}
