import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimePipe} from './time.pipe';
import {DatePipe} from './date.pipe';
import {SlashDatePipe} from './slash-date.pipe';
import {TimeMinutesPipe} from './time-minutes.pipe';


@NgModule({
	declarations: [TimePipe, DatePipe, SlashDatePipe, TimeMinutesPipe],
	exports: [
		TimePipe,
		DatePipe,
		SlashDatePipe,
		TimeMinutesPipe
	],
	imports: [
		CommonModule
	]
})
export class DisplayedTimeModule {
}
