import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Schedule} from '@models/schedule';
import {ScheduleService} from '@services/schedule.service';

@Component({
    selector: 'app-schedule-selector',
    templateUrl: './schedule-selector.component.html',
    styleUrls: ['./schedule-selector.component.scss'],
})
export class ScheduleSelectorComponent implements OnInit {
    @Output() scheduleIdChange: EventEmitter<number> = new EventEmitter();
    @Input() required: boolean = false;
    @Input() showUnpublished = true;
    schedules: Schedule[] = [];
    private _scheduleId: number;

    constructor(private scheduleService: ScheduleService) {
    }

    ngOnInit() {
        this.getSchedules();
    }

    private getSchedules() {
        this.scheduleService.getAll().subscribe(
            schedules => {
                this.schedules = schedules;
            }
        );
    }

    compare(o1, o2) {
        return o1 == o2;
    }

    get scheduleId(): number {
        return this._scheduleId;
    }

    @Input() set scheduleId(id: number) {
        if (id && this._scheduleId != id) {
            this._scheduleId = id;
            this.scheduleIdChange.emit(id);
        }
    }
}
