import {Directive, ElementRef} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Directive({
    selector: '[appOverflowHidden]'
})
export class OverflowHiddenDirective {

    constructor(el: ElementRef, private deviceService: DeviceDetectorService) {
        if (!(deviceService.isMobile() && deviceService.getDeviceInfo().browser == 'Safari')) {
            (el.nativeElement as HTMLTableElement).style.overflowX = 'hidden';
        }
    }
}
