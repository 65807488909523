import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-status-icon',
    templateUrl: './status-icon.component.html',
    styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent {

    @Input() state: boolean;
}
