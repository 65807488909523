import {UnavailabilityStructure} from '@structures/unavailability.structure';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ScheduleItem} from '@models/schedule-item';
import {User} from '@models/user';
import {Persists} from '@models/new-persist/Persistable';
import {UnavailabilityInterface} from '@models/interfaces/UnavailabilityInterface';

export class Unavailability extends ScheduleItem implements Persists, UnavailabilityInterface {
    protected _colorCode: string = '#808080';
    private _scheduleId: number;
    private _schedulableEntityId: number;
    reason: string;
    private _location: string;
    private _apply_partner: boolean = false;
    private _followWithStroking: boolean = false;
    repetition: any;
	schedulableEntity: any;
	contract_id: number;
	is_resurfacing: boolean = false;

	json(): string {
		return JSON.stringify({unavailability: this.structure});
	}

	jsonObject(): { unavailability: UnavailabilityStructure } {
		return;
	}

	get followWithStroking(): boolean {
		return this._followWithStroking;
	}

	fromJson(json: UnavailabilityStructure): Unavailability {
		this.scheduleId = json.schedule_id;
		this.timeBegin = moment.utc(json.time_begin);
		this.timeEnd = moment.utc(json.time_end);
		this.schedulableEntityId = json.schedulable_entity_id;
		this.reason = json.reason;
		this.contract_id = json.contract_id;
		this.id = json.id;
		this.apply_partner = json.apply_partner;
		this.followWithStroking = json.follow_with_stroking;
		this._location = json.location;
		if (this.reason)
			this.is_resurfacing = this.reason.includes('resurfacing') || this.reason.includes('Resurfacing');
		return this;
	}

	set followWithStroking(value: boolean) {
		this._followWithStroking = value;
	}

	get apply_partner(): boolean {
		return this._apply_partner;
	}

	set apply_partner(value: boolean) {
        this._apply_partner = value;
    }

    get location(): string {
        return this._location;
    }

    set location(value: string) {
        this._location = value;
    }

    get scheduleId(): number {
        return this._scheduleId;
    }

    set scheduleId(value: number) {
        this._scheduleId = value;
    }

    get schedulableEntityId(): number {
        if (this.schedulableEntity) {
            return this.schedulableEntity.id;
        }
        return this._schedulableEntityId;
    }

	set schedulableEntityId(value: number) {
		this._schedulableEntityId = value;
	}

	belongsTo(user: User): boolean {
		return this.schedulableEntityId == user.id || this.schedulableEntityId == user.partnerId;
	}

	getDefinition(): string[] {
		return [];
	}

	map(json) {
        if (json)
            return this.fromJson(json);
    }

	get date(): Moment {
		return this.timeBegin;
	}

	get structure(): UnavailabilityStructure {
		return {
			schedule_id: this.scheduleId,
			time_begin: this.timeBegin.toISOString(false),
			time_end: this.timeEnd.toISOString(false),
			schedulable_entity_id: this.schedulableEntityId,
			follow_with_stroking: this.followWithStroking,
			reason: this.reason,
			location: this.location,
			apply_partner: this.apply_partner,
			id: this.id
		};
	}

	get isReasonRequired() {
		return this.schedulableEntity.isUser && this.schedulableEntity.user.isStudent;
	}

	get is_all_day() {
		return this.timeStartMinuteOfDay == 0 && this.timeEndMinuteOfDay == 0
	}
}
