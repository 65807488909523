import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionsService} from '@services/sessions.service';

@Injectable({
    providedIn: 'root'
})
export class PasswordExpiryGuard implements CanActivate {
    constructor(private sessionsService: SessionsService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.sessionsService.current_user && this.sessionsService.current_user.passwordExpired) {
            this.router.navigate(['/profile/edit']);
            return false;
        }
        return true;
    }

}
