import {ContractStructure} from "@structures/contract.structure";
import {Contract} from "@models/contract";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";

export class ContractFactory extends AbstractPersistableFactory<any> {
	userFactory: AbstractPersistableFactoryInterface

	make(json: ContractStructure): Contract {
		let contract = (new Contract()).fromJson(json);

		if (json.partner)
			contract.partner = this.userFactory.make(json.partner);
		if (json.student)
			contract.student = this.userFactory.make(json.student);
		return contract;
	}
}
