import {SchedulableEntity} from "@models/schedulable-entity";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";

export class SchedulableEntityFactory extends AbstractPersistableFactory<any> {
	skatingRingFactory: AbstractPersistableFactoryInterface;
	userFactory: AbstractPersistableFactoryInterface;

	make(json: any): any {
		let se = new SchedulableEntity().fromJson(json) as SchedulableEntity;
		if (json.user) {
			se.user = this.userFactory.make(json.user);
		}
		if (json.skating_ring) {
			se.skatingRing = this.skatingRingFactory.make(json.skating_ring);
		}
		return se;
	}
}
