import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";
import {Persists} from "@models/new-persist/Persistable";

export abstract class AbstractPersistableFactory<T extends Persists> implements AbstractPersistableFactoryInterface {
	abstract make(json: any): any;

	makeMany(json: any[]): any[] {
		if (json == null)
			return [];
		return (json as any[]).map(item => this.make(item));
	}

}
