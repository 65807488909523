import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {ScheduleTime} from "@models/schedule-time";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";

export class ScheduleTimeFactory extends AbstractPersistableFactory<any> {
	skatingRingFactory: AbstractPersistableFactoryInterface;
	teacherFactory: AbstractPersistableFactoryInterface;
	contractFactory: AbstractPersistableFactoryInterface;
	activityFactory: AbstractPersistableFactoryInterface;

	make(json: any): any {
		let scheduleTime = new ScheduleTime().fromJson(json);

		if (json.skating_ring)
			scheduleTime.skatingRing = this.skatingRingFactory.make(json.skating_ring);
		if (json.teacher)
			scheduleTime.teacher = this.teacherFactory.make(json.teacher);
		if (json.contract)
			scheduleTime.contract = this.contractFactory.make(json.contract);
		if (json.activity)
			scheduleTime.activity = this.activityFactory.make(json.activity);
		return scheduleTime;
	}

}
