import {User} from './user';
import {TeacherElements, TeacherStructure} from '@structures/teacher.structure';
import {UsersStructure} from '@structures/users.structure';
import {Role} from '@models/role';
import {SchedulableEntityStructure} from '@structures/schedulable-entity.structure';

export class Teacher extends User {
    roleIds: number[] = [];
    roles: Role[];
    order_number: number;
    unavailabilities: any[] = [];

    fromJson(json: TeacherStructure): this {
        super.fromJson(json);
        this.order_number = json.order_number;
		if (json.role_ids)
			this.roleIds = json.role_ids;
		return this;
	}

    apiPayloadStructure(): { user: UsersStructure, teacher: TeacherElements, schedulable_entity: SchedulableEntityStructure } {
        return {
            schedulable_entity: {school_id: this.school_id},
            user: this.userStructure(),
            teacher: {
                role_ids: this.roleIds
            }
        };
    }

	get structure(): TeacherStructure {
		let data = this.userStructure() as TeacherStructure;
		data.role_ids = this.roleIds;
		return data;
	}

	get assignedGroups() {
		if (this.roles)
			return this.roles.map(value => value.name).join(', ')
		return ""
	}

	belongsToRole(roleId: number): boolean {
		let roleIds: number[] = this.roleIds;
		if (!this.roleIds) {
			if (!this.roles)
				return false;
			roleIds = this.roles.map(value => value.id)
		}
		return roleIds.find(id => id == roleId) != null;
	}
}
