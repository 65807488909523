import {User} from './user';
import {StudentElements, StudentStructure} from '@structures/student.structure';
import {UsersStructure} from '@structures/users.structure';
import * as moment from 'moment';
import {StudentInterface} from '@models/interfaces/StudentInterface';
import {Contract} from '@models/contract';
import {SchedulableEntityStructure} from '@structures/schedulable-entity.structure';

export class Student extends User implements StudentInterface {
    assignedGroup: any;
    assignedGroupId: number;
    contracts: any[] = [];
    private _currentContractId: number | null;


    fromJson(json: StudentStructure): this {
        super.fromJson(json);
		this.assignedGroupId = json.assigned_group_id;
		this._currentContractId = json.current_contract_id;
		return this;
	}

    apiPayloadStructure(): { user: UsersStructure; student: StudentElements, schedulable_entity: SchedulableEntityStructure } {
        return {
            schedulable_entity: {school_id: this.school_id},
            user: this.userStructure(),
            student: {
                assigned_group_id: this.assignedGroupId,
                contracts: this.contracts.map(contract => contract.structure),
                partner_id: this.partnerId,
                current_contract_id: this.currentContractId
            }
        };
	}

	belongsToRole(roleId: number): boolean {
		return this.assignedGroupId == roleId || (this.assignedGroup && this.assignedGroup.id == roleId);
	}

	get structure(): StudentStructure {
		let data = this.userStructure() as StudentStructure;
		data.assigned_group_id = this.assignedGroupId;
		data.current_contract_id = this.currentContractId;
		return data;
	}

	get currentContract(): Contract | null {
		return this.contracts.find(contract => contract.validUntil.isAfter(moment.utc()));
	}

	get currentContractId(): number | null {
		if (this.currentContract)
			return this.currentContract.id;
		return this._currentContractId;
	}

	set currentContractId(value: number | null) {
		this._currentContractId = value;
	}
}
