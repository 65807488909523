import {Injectable} from '@angular/core';
import Pusher, {Channel} from 'pusher-js';
import {even} from "@rxweb/reactive-form-validators";
import {Observable, Subject} from "rxjs";
import {environment} from "@environments/environment";

@Injectable({
	providedIn: 'root'
})
export class PusherService {
	private pusher: Pusher;
	private channels: { solver?: Channel } = {};

	constructor() {
		this.pusher = new Pusher(environment.pusherKey, {
			cluster: 'us2'
		});
		this.channels.solver = this.pusher.subscribe('solver');
	}

	getEvent(channel: string, event: string): Observable<string> {
		let subscription = new Subject<string>();
		this.getChannel(channel).bind(event, (event) => subscription.next(event));
		return subscription.asObservable();
	}

	getChannel(channel): Channel {
		return this.channels[channel]
	}
}
