import {version} from './version';

export let environment = {
    apiUrl: 'https://rail-api-staging.iceacademyofmontreal.com',
    environment: 'staging',
    version: "staging: " + version,
    pusherKey: "0a947f393dfb1fca190e",
    solverApiUrl: 'https://solver.dlhschool.ca',
    laravelApiUrl: 'https://api-staging.iceacademyofmontreal.com',
};
