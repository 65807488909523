import {PersistableStructure} from '@structures/persistable.structure';

export abstract class Persistable {
    private _id: number;

    abstract fromJson(persistable: PersistableStructure): Persistable;

    abstract structure: PersistableStructure;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    abstract json(): string;
}
