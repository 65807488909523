import {UnavailabilityStructure} from "@structures/unavailability.structure";
import {Unavailability} from "@models/unavailability";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";

export class UnavailabilityFactory extends AbstractPersistableFactory<any> {
	schedulableEntityFactory: AbstractPersistableFactoryInterface;
	repetitionFactory: AbstractPersistableFactoryInterface;


	public make(json: UnavailabilityStructure) {
		let un = new Unavailability().fromJson(json);

		if (json.schedulable_entity)
			un.schedulableEntity = this.schedulableEntityFactory.make(json.schedulable_entity);
		if (json.repetition)
			un.repetition = this.repetitionFactory.make(json.repetition);
		return un;
	}
}
