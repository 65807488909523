import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class ExpiryInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((event) => this.manageExpiryErrors(event))
        );
    }

    private manageExpiryErrors(error: HttpErrorResponse) {
        if (error.error && error.status == 401 && error.error.error == 'Signature has expired') {
            this.router.navigate(['/login']);
        }
        return throwError(error);
    }

}
