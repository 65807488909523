import * as moment from 'moment';
import {Moment} from 'moment';
import {User} from '@models/user';
import {Persistable} from '@models/persistable';

export abstract class ScheduleItem extends Persistable {
	public applyPartner: boolean;
	timeBeginUnix: number;
	timeEndUnix: number;
	protected _colorCode: string = '#808080';
	timeStartMinuteOfDay: number;
	timeStartFifteenMinuteUnit: number;
	timeEndFifteenMinuteUnit: number;
	timeEndMinuteOfDay: number;
	private _timeBegin: Moment = moment.utc();
	private _timeEnd: Moment = moment.utc();
	is_deleted: boolean = false;

	constructor() {
		super();
		this._timeBegin.set('minutes', 0);
		this._timeBegin.set('hours', 7);
		this._timeEnd.set('minutes', 0);
		this._timeEnd.set('hours', 7);
	}

	lengthInMinutes(): number {
		return Math.abs(this._timeBegin.diff(this._timeEnd, 'minute'))
	}

	abstract belongsTo(user: User): boolean;

	get timeEnd(): moment.Moment {
		return this._timeEnd;
	}

	get timeStart(): moment.Moment {
		return this._timeBegin;
	}

	set timeStart(value: moment.Moment) {
		this.timeBegin = value
	}

	moveTime(selectedTime: Moment) {
		let duration = this.durationMinutes;
		this.timeStart = selectedTime.clone();
		this.timeEnd = selectedTime.clone().add(duration, "minutes");
	}

	set date(value: moment.Moment) {
		if (this.timeEnd && this.timeStart) {
			this.timeStart.year(value.year());
			this.timeStart.month(value.month());
			this.timeStart.date(value.date());
			this.timeEnd.year(value.year());
			this.timeEnd.month(value.month());
			if (this.timeEndMinuteOfDay == 0)
				this.timeEnd.date(value.clone().add(1, 'day').date());
			else
				this.timeEnd.date(value.date());
		}
	}

	set timeEnd(value: Moment) {
		if (value) {
			if (!value.isValid()) {
				console.error("Invalid end time for schedule item");
				return;
			}
			this._timeEnd = value.clone();
			this.timeEndUnix = value.unix();
			this.timeEndMinuteOfDay = value.diff(value.clone().startOf('day'), 'minutes');
			if (this.timeEndMinuteOfDay == 0)
				this.timeEndFifteenMinuteUnit = 24 * 4
			else
				this.timeEndFifteenMinuteUnit = this.timeEndMinuteOfDay / 15;
		}
	}

	get timeBegin(): moment.Moment {
		return this._timeBegin;
	}

	set timeBegin(value: moment.Moment) {
		if (value) {
			if (!value.isValid()) {
				console.error("Invalid start time for schedule item");
				return;
			}
			this._timeBegin = value.clone();
			this.timeBeginUnix = value.unix();
			this.timeStartMinuteOfDay = value.diff(value.clone().startOf('day'), 'minutes');
			this.timeStartFifteenMinuteUnit = this.timeStartMinuteOfDay / 15;
		}
	}

	/**
	 * color code for blocks. Leave here to allow override by class implementation
	 */
	get colorCode(): string {
		return this._colorCode;
	}

	set colorCode(value: string) {
		this._colorCode = value;
	}

	get dayMinuteBegin() {
		return this._timeBegin.hours() * 60 + this._timeBegin.minutes();
	}

	get dayMinuteEnd() {
		return this._timeEnd.hours() * 60 + this._timeEnd.minutes();
	}

	get durationMinutes(): number {
		return Math.abs(this.timeStart.diff(this.timeEnd, "minutes"))
	}
}
