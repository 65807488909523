import {Injectable} from "@angular/core";
import {DailyScheduleFactory} from "@factories/DailyScheduleFactory";
import {ScheduleFactory} from "@factories/schedule.factory";
import {UnavailabilityFactory} from "@factories/UnavailabilityFactory";
import {SchedulableEntityFactory} from "@factories/SchedulableEntityFactory";
import {SkatingRingFactory} from "@factories/SkatingRingFactory";
import {ScheduleTimeFactory} from "@factories/ScheduleTimeFactory";
import {ContractFactory} from "@factories/ContractFactory";
import {TeacherFactory} from "@factories/TeacherFactory";
import {UserFactory} from "@factories/user.factory";
import {StudentFactory} from "@factories/StudentFactory";
import {RequiredTimeFactory} from "@factories/RequiredTimeFactory";
import {RepetitionFactory} from "@factories/RepetitionFactory";
import {ActivityFactory} from "@factories/ActivityFactory";

@Injectable({
	providedIn: 'root'
})
export class PersistableFactoryFactory {
	private _scheduleFactory: ScheduleFactory;
	private _dailyScheduleFactory: DailyScheduleFactory;
	private _unavailabilityFactory: UnavailabilityFactory;
	private _schedulableEntityFactory: SchedulableEntityFactory;
	private _skatingRingFactory: SkatingRingFactory;
	private _scheduleTimeFactory: ScheduleTimeFactory;
	private _contractFactory: ContractFactory;
	private _teacherFactory: TeacherFactory;
	private _userFactory: UserFactory;
	private _studentFactory: StudentFactory;
	private _requiredTimeFactory: RequiredTimeFactory;
	private _repetitionFactory: RepetitionFactory;
	private _activityFactory: ActivityFactory;

	get studentFactory(): StudentFactory {
		if (!this._studentFactory) {
			this._studentFactory = new StudentFactory()
			this._studentFactory.contractFactory = this.contractFactory
		}
		return this._studentFactory;
	}

	get userFactory(): UserFactory {
		if (!this._userFactory) {
			this._userFactory = new UserFactory();
			this._userFactory.teacherFactory = this.teacherFactory;
			this._userFactory.studentFactory = this.studentFactory;
		}
		return this._userFactory;
	}

	get teacherFactory(): TeacherFactory {
		if (!this._teacherFactory) {
			this._teacherFactory = new TeacherFactory();
		}
		return this._teacherFactory;
	}

	get activityFactory(): ActivityFactory {
		if (!this._activityFactory) {
			this._activityFactory = new ActivityFactory();
		}
		return this._activityFactory;
	}

	get contractFactory(): ContractFactory {
		if (!this._contractFactory) {
			this._contractFactory = new ContractFactory();
			this._contractFactory.userFactory = this.userFactory
		}
		return this._contractFactory;
	}

	get skatingRingFactory(): SkatingRingFactory {
		if (!this._skatingRingFactory) {
			this._skatingRingFactory = new SkatingRingFactory();
			this._skatingRingFactory.unavailabilityFactory = this.unavailabilityFactory
		}
		return this._skatingRingFactory;
	}

	get schedulableEntityFactory(): SchedulableEntityFactory {
		if (!this._schedulableEntityFactory) {
			this._schedulableEntityFactory = new SchedulableEntityFactory();
			this._schedulableEntityFactory.skatingRingFactory = this.skatingRingFactory
			this._schedulableEntityFactory.userFactory = this.userFactory
		}
		return this._schedulableEntityFactory;
	}

	get unavailabilityFactory(): UnavailabilityFactory {
		if (!this._unavailabilityFactory) {
			this._unavailabilityFactory = new UnavailabilityFactory();
			this._unavailabilityFactory.schedulableEntityFactory = this.schedulableEntityFactory
			this._unavailabilityFactory.repetitionFactory = this.repetitionFactory
		}
		return this._unavailabilityFactory;
	}

	get scheduleFactory(): ScheduleFactory {
		if (!this._scheduleFactory) {
			this._scheduleFactory = new ScheduleFactory();
			this._scheduleFactory.dailyScheduleFactory = this.dailyScheduleFactory
		}
		return this._scheduleFactory;
	}

	get dailyScheduleFactory(): DailyScheduleFactory {
		if (!this._dailyScheduleFactory) {
			this._dailyScheduleFactory = new DailyScheduleFactory();
			this._dailyScheduleFactory.scheduleFactory = this.scheduleFactory
			this._dailyScheduleFactory.scheduleTimeFactory = this.scheduleTimeFactory
		}
		return this._dailyScheduleFactory;
	}

	get scheduleTimeFactory(): ScheduleTimeFactory {
		if (!this._scheduleTimeFactory) {
			this._scheduleTimeFactory = new ScheduleTimeFactory();
			this._scheduleTimeFactory.skatingRingFactory = this.skatingRingFactory;
			this._scheduleTimeFactory.activityFactory = this.activityFactory;
			this._scheduleTimeFactory.contractFactory = this.contractFactory;
			this._scheduleTimeFactory.teacherFactory = this.teacherFactory;
		}
		return this._scheduleTimeFactory;
	}

	get requiredTimeFactory(): RequiredTimeFactory {
		if (!this._requiredTimeFactory) {
			this._requiredTimeFactory = new RequiredTimeFactory();
			this._requiredTimeFactory.contractFactory = this.contractFactory;
			this._requiredTimeFactory.teacherFactory = this.teacherFactory;
		}
		return this._requiredTimeFactory;
	}

	get repetitionFactory(): RepetitionFactory {
		if (!this._repetitionFactory) {
			this._repetitionFactory = new RepetitionFactory();
			this._repetitionFactory.unavailabilityFactory = this.unavailabilityFactory;
		}
		return this._repetitionFactory;
	}
}
