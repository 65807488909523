import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FocusItemDirective} from './focus-item.directive';
import {ConfirmableDeleteDirective} from './confirmable-delete.directive';
import {PaddingDirective} from './padding.directive';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {LinkDirective} from './link.directive';

@NgModule({
	declarations: [
		FocusItemDirective,
		ConfirmableDeleteDirective,
		PaddingDirective,
		LinkDirective],
	exports: [
		FocusItemDirective,
		ConfirmableDeleteDirective,
		PaddingDirective,
		LinkDirective
	],
	imports: [
		CommonModule,
		MatBottomSheetModule
	]
})
export class CustomDirectivesModule {
}
