import {SchedulableEntityStructure} from '@structures/schedulable-entity.structure';
import {Persistable} from '@models/persistable';
import {SchedulableEntityInterface} from '@models/interfaces/SchedulableEntityInterface';

export class SchedulableEntity extends Persistable implements SchedulableEntityInterface {
    user: any;
    skatingRing: any;
    school_id: number;

    constructor() {
        super();
    }

	fromJson(persistable: SchedulableEntityStructure): Persistable {
		this.id = persistable.id
		return this;
	}

	json() {
		return JSON.stringify(this.structure);
	}

	get structure(): SchedulableEntityStructure {
		return {
			id: this.id
		}
	}

	get isSkatingRing(): boolean {
		return this.skatingRing != null;
	}

	get isUser(): boolean {
		return this.user != null;
	}

    get name() {
        if (this.isUser) {
            return this.user.first_name + ' ' + this.user.last_name;
        }
        if (this.isSkatingRing) {
            return this.skatingRing.name;
        }
        return '';
    }

    get isAdmin() {
        return this.isUser && this.user.admin;
    }

    get type() {
        return this.isSkatingRing ? 'Skating Ring' : (this.isAdmin ? 'Admin' : this.user.userType);
    }
}
