import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {Paginator} from '@models/Paginator';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LaravelApiService {
    protected LARAVEL_API_TOKEN = 'laravel_api_token';
    protected LARAVEL_CURRENT_USER_ID = 'laravel_current_user_id';
    private multipart: boolean = false;
    protected persists: boolean = false;

    constructor(protected http: HttpClient) {
        this.afterBuild();
    }

    protected apiUrl() {
        return environment.laravelApiUrl + '/user';
    }

    protected postHttp<T>(endpoint: string, payload: any, options: ApiRequestOptions = {}): Observable<T> {
        if (payload.toJson != null)
            payload = payload.toJson();
        return this.http.post<T>(this.apiUrl() + endpoint, payload, {headers: this.getAuthHeaders()})
            .pipe(map(value => this.mapPersistable<T>(value, options)));
    }

    protected putHttp<T>(endpoint: string, payload: any, options: ApiRequestOptions = {}): Observable<T> {
        if (payload.toJson != null)
            payload = payload.toJson();
        return this.http.put<T>(this.apiUrl() + endpoint, payload, {headers: this.getAuthHeaders()})
            .pipe(map(value => this.mapPersistable<T>(value, options)));
    }

    protected getHttp<T>(endpoint: string, options?: HttpParams | {
        [param: string]: string | string[] | HttpParams;
    }, apiRequestOptions: ApiRequestOptions = {}): Observable<T> {
        const getObs = this.http.get<T>(this.apiUrl() + endpoint, Object.assign({headers: this.getAuthHeaders()}, options))
        return getObs.pipe(map(value => {
            if (this.isAPaginator(value))
                return this.mapPaginator(value)
            return this.mapPersistable<T>(value, apiRequestOptions);
        }));
    }

    protected deleteHttp<T>(endpoint: string, options: ApiRequestOptions = {}): Observable<T> {
        return this.http.delete<T>(this.apiUrl() + endpoint, {headers: this.getAuthHeaders()})
            .pipe(map(value => this.mapPersistable<T>(value, options)));
    }

    protected getAuthHeaders(): HttpHeaders {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', '' + this.getToken());
        headers = this.setActAsInfo(headers)
        if (!this.multipart)
            return headers.append('Content-Type', 'application/json');
        this.multipart = false;
        return headers;
    }

    protected mapMany(json: any[]) {
        return json.map(value => this.map(value));
    }

    public map(json: any) {
        return json;
    }

    protected mapPaginator<T>(json): Paginator<T> {
        let playerPaginator: Paginator<T> = Paginator.map<Paginator<T>>(json, Paginator);
        playerPaginator.mapPersistables((item: T) => {
            return this.map(item)
        });
        return playerPaginator;
    }

    private mapPersistable<T>(json: T, options: ApiRequestOptions) {
        if (this.persists && (options.map_persistable == null || options.map_persistable)) {
            if (Array.isArray(json))
                return this.mapMany(json);
            else
                return this.map(json);
        }

        return json;
    }

    protected getToken() {
        return localStorage.getItem(this.LARAVEL_API_TOKEN);
    }

    private setActAsInfo(options: HttpHeaders) {
        let token = localStorage.getItem('act_as_token');
        if (token)
            return options.append("PHO_ACT_AS_TOKEN", token);
        return options;
    }

    private isAPaginator(value: any) {
        return value.current_page != null && value.from != null;
    }

    protected afterBuild() {

    }
}

export interface ApiRequestOptions {
    map_persistable?: boolean
}
