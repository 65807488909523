import {ApplicationRef, Compiler, Injector, NgModule} from '@angular/core';
import {ChildrenOutletContexts, Router, RouterModule, ROUTES, Routes, UrlSerializer} from '@angular/router';
import {adminRoutes} from './admin.routing';
import {Location} from '@angular/common';
import {LoggedInGuard} from './guards/logged-in.guard';
import {InterceptorsModule} from '@interceptors/interceptors.module';
import {PasswordExpiryGuard} from './guards/password-expiry.guard';
import {SchoolGuard} from '@modules/school/school.guard';
import {routerFactory} from '@services/app-router.service';
import {SchoolService} from '@modules/school/school.service';
import {LaravelSessionGuard} from "./guards/laravel-session.guard";

export const routes: Routes = [
    // {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginPageModule)},
    {path: 'sign-up', loadChildren: () => import('./modules/sign-up/sign-up.module').then(m => m.SignUpPageModule)},
    {
        path: '', canActivate: [LoggedInGuard, LaravelSessionGuard], children: [
            {
                path: 'profile',
                loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfilePageModule)
            },
            {
                path: '', canActivate: [PasswordExpiryGuard], children: [

                    {
                        path: 'schedules',
                        loadChildren: () => import('./modules/schedules/schedule-user.module').then(m => m.ScheduleUserModule)
                    },
                    {
                        path: 'schools',
                        loadChildren: () => import('./modules/school/school.module').then(m => m.SchoolModule)
                    },
                    {
                        path: 'repetitions',
                        loadChildren: () => import('./modules/repetitions/repetitions.module').then(m => m.RepetitionsModule)
                    },
                    {
                        path: 'unavailabilities',
                        loadChildren: () => import('./modules/unavailabilities/unavailabilities.module').then(m => m.UnavailabilitiesModule)
                    },
                    {path: ':school_code/admin', canActivateChild: [SchoolGuard], children: adminRoutes},
                    {path: '**', redirectTo: 'schools/select'},
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        InterceptorsModule,
        RouterModule.forRoot(routes)
    ],
    providers: [LoggedInGuard,
        {
            provide: Router,
            useFactory: routerFactory,
            deps: [
                ApplicationRef,
                UrlSerializer,
                ChildrenOutletContexts,
                Location,
                Injector,
                Compiler,
                ROUTES,
                SchoolService
            ]
        }],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
