import {Routes} from '@angular/router';

export const adminRoutes: Routes = [
	{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
	{
		path: 'dashboard',
		loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardPageModule)
	},
	{
		path: 'schedules',
		loadChildren: () => import('./modules/schedules/schedule.module').then(m => m.SchedulePageModule)
	},
	{
		path: 'locations',
		loadChildren: () => import('./modules/locations/locations.module').then(m => m.LocationsModule)
	},
	{
		path: 'daily_schedules',
		loadChildren: () => import('./modules/daily-schedules/daily-schedules.module').then(m => m.DailySchedulesModule)
	},
	{
		path: 'unavailabilities',
		loadChildren: () => import('./modules/unavailabilities/unavailabilities.module').then(m => m.UnavailabilitiesModule)
	},
	{
		path: 'skating-rings',
		loadChildren: () => import('./modules/skating-rings/skating-rings.module').then(m => m.SkatingRingsPageModule)
	},
	{path: 'users', loadChildren: () => import('./modules/users/users.module').then(m => m.UsersPageModule)},
	{
		path: 'schedulable-entities',
		loadChildren: () => import('./modules/schedulable-entity/schedulable-entity.module').then(m => m.SchedulableEntityPageModule)
	},
	{
		path: 'notification-types',
		loadChildren: () => import('./modules/notifications/notification-types/notification-types.module').then(m => m.NotificationTypesPageModule)
	},
	{
		path: 'notification-subscriptions',
		loadChildren: () => import('./modules/notifications/notification-subscriptions/notification-subscriptions.module').then(m => m.NotificationSubscriptionsPageModule)
    },
    {
        path: 'notification-sendings',
	    loadChildren: () => import('./modules/notifications/notification-sendings/notification-sendings.module').then(m => m.NotificationSendingsPageModule)
    },
    {
        path: 'roles',
	    loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule)
    },
	{
		path: 'contracts',
		loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule)
	},
	{
		path: 'required-times',
        loadChildren: () => import('./modules/required-times/required-times.module').then(m => m.RequiredTimesModule)
    },
    {
        path: 'invitations',
        loadChildren: () => import('./modules/invitations/invitations.module').then(m => m.InvitationsPageModule)
    },
    {
        path: 'activities',
        loadChildren: () => import('./modules/activities/activities.module').then(m => m.ActivitiesModule)
    },
    {
        path: 'configuration',
        loadChildren: () => import('./modules/config/config.module').then(m => m.ConfigModule)
    },
    {path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsPageModule)},
];
