import {ScheduleTimeStructure} from '@structures/schedule-time.structure';
import {ScheduleItem} from '@models/schedule-item';
import {Teacher} from '@models/teacher';
import {SkatingRing} from '@models/skating-ring';
import {Contract} from '@models/contract';
import {User} from '@models/user';
import {Persists} from "@models/new-persist/Persistable";
import * as moment from "moment";
import {ScheduleTimeInterface} from "@models/interfaces/ScheduleTimeInterface";
import {ScheduleTypeEnum} from "@modules/schedule-maker/schedule-type-enum";
import {Activity} from "@models/Activity";

export class ScheduleTime extends ScheduleItem implements Persists, ScheduleTimeInterface {
	private _activity: any;

	get activity(): any {
		return this._activity;
	}

	private _contractId: number;
	private _contract: Contract;
	private _teacherId: number;
	private _skatingRingId: number;

	set activity(value: any) {
		if (value)
			this.activity_id = value.id
		this._activity = value;
	}

	private _teacher: Teacher;
	private _skatingRing: SkatingRing;
	daily_schedule_id: number;
	activity_id: number;

	json() {
		return JSON.stringify({schedule_time: this.structure});
	}

	belongsTo(user: User): boolean {
		return this.teacherId == user.id || (this.contract && (this.contract.studentId == user.id || this.contract.partnerId == user.id));
	}

	get contract(): Contract {
		return this._contract;
	}

	get colorCode() {
		if (this.teacher && this.teacher.colorCode)
			return this.teacher.colorCode;
		else return '#ffffff';
	}

	get contrast_color_code() {
		if (this.teacher && this.teacher.contrast_color_code)
			return this.teacher.contrast_color_code;
		else return '#000';
	}

	fromJson(param: ScheduleTimeStructure): ScheduleTime {
		this.daily_schedule_id = param.daily_schedule_id;
		this.timeBegin = moment.utc(param.time_begin);
		this.timeEnd = moment.utc(param.time_end);
		this.teacherId = param.teacher_id;
		this.contractId = param.contract_id;
		this.skatingRingId = param.skating_ring_id;
		this.activity_id = param.activity_id;
		this.id = param.id;
		return this;
	}

	get skatingRing(): SkatingRing {
		return this._skatingRing;
	}

	set skatingRing(value: SkatingRing) {
		this._skatingRing = value;
	}

	get teacher(): Teacher {
        return this._teacher;
    }

    set teacher(value: Teacher) {
        this._teacher = value;
    }

    get contractId(): number {
        if (this.contract && this.contract.id)
            return this.contract.id;
        return this._contractId;
    }

    set contractId(value: number) {
        this._contractId = value;
    }

    get skatingRingId(): number {
        return this._skatingRingId;
    }

    set skatingRingId(value: number) {
        this._skatingRingId = value;
    }

	get teacherId(): number {
		if (this.teacher)
			return this.teacher.id;
		return this._teacherId;
	}

	set teacherId(value: number) {
		this._teacherId = value;
	}

	set colorCode(value) {
		super.colorCode = value;
	}

	getDefinition(): string[] {
		return [];
	}

	map(json) {
	}

	get structure(): ScheduleTimeStructure {
		return {
			daily_schedule_id: this.daily_schedule_id,
			time_begin: this.timeBegin.toISOString(false),
			time_end: this.timeEnd.toISOString(false),
			teacher_id: this.teacherId,
			contract_id: this.contractId,
			skating_ring_id: this.skatingRingId,
			activity_id: this.activity_id,
			id: this.id
		};
	}

	set contract(value: Contract) {
		this._contract = value;
		if (value)
			this._contractId = value.id
	}

	shouldBeDisplayedForScheduleType(scheduleTypeEnum: ScheduleTypeEnum) {
		return (this.activity as Activity).shouldBeDisplayedForScheduleType(scheduleTypeEnum);
	}
}
