import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {RequiredTime} from "@models/RequiredTime";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";

export class RequiredTimeFactory extends AbstractPersistableFactory<any> {
	teacherFactory: AbstractPersistableFactoryInterface;
	contractFactory: AbstractPersistableFactoryInterface;

	make(json: any): any {
		let requiredTime = (new RequiredTime()).fromJson(json);
		if (json.teacher)
			requiredTime.teacher = this.teacherFactory.make(json.teacher);
		if (json.contract)
			requiredTime.contract = this.contractFactory.make(json.contract);
		return requiredTime;
	}

}
