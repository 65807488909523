export abstract class Persistable<T extends Persists> {
	id: number;
	protected hasId: boolean = true;

	abstract getDefinition(): string[];

	public map(json: T): Persistable<T> {
		if (!json)
			console.error("Trying to map an object from null value!")
		this.getDefinition().forEach(
			value => this[value] = json[value] || null
		);
		if (this.hasId)
			this.id = json.id;
		this.afterMap(json);
		return this;
	}

	public toJsonStructure() {
		let structure: any = {};
		if (this.hasId)
			structure.id = this.id;
		this.getDefinition().forEach(
			value => structure[value] = this[value] || null
		);
		return structure;
	}

	protected afterMap(json: T) {

	}
	static map<T extends any>(json: T, type: (new () => T)): T {
		if (json === null || json == undefined)
			return;
		let concreteClass: T = (new type());
		return (concreteClass as any).map(json)
	}

	toJson() {
		let json = {};
		this.getFullDefinition().forEach(def => {
			json[def] = this[def];
		});
		return json;
	}
	private getFullDefinition() {
		let definitions = this.getDefinition();
		if (this.hasId)
			definitions.push('id');
		return definitions
	}
}

export interface Persists {
	id: number;

	getDefinition(): string[];

	map(json);
}
