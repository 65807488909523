import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomAngularFormControl} from '@app-shared/materials/custom-angular-form-control';

@Component({
    selector: 'app-user-type-selector',
    templateUrl: './user-type-selector.component.html',
    styleUrls: ['./user-type-selector.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserTypeSelectorComponent),
            multi: true
        }
    ]
})
export class UserTypeSelectorComponent extends CustomAngularFormControl<string> implements OnInit {

    @Input() control: FormControl;

    ngOnInit(): void {
    }


}
