import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthenticationInterceptor} from './authentication.interceptor';
import {ContentTypeInterceptor} from './content-type.interceptor';
import {ErrorHandlerInterceptor} from './error-handler.interceptor';
import {ExpiryInterceptor} from './expiry.interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    imports: [
        MatSnackBarModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ExpiryInterceptor, multi: true}
    ]
})
export class InterceptorsModule {

}
