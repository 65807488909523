import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {DestroyConfirmationDataStructure} from './destroy-confirmation-data.structure';

@Component({
    selector: 'app-destroy-confirmation',
    templateUrl: './destroy-confirmation.component.html',
    styleUrls: ['./destroy-confirmation.component.scss']
})
export class DestroyConfirmationComponent implements OnInit {

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: DestroyConfirmationDataStructure, private bottomSheetRef: MatBottomSheetRef<DestroyConfirmationComponent>) {
    }

    ngOnInit() {
    }

    destroy(event: MouseEvent): void {
        this.submitResults(true);
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }

    dismiss() {
        this.submitResults(false);
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }

    submitResults(result: boolean) {
        this.data.destroySubject.next(result);
        this.data.destroySubject.complete();
    }
}
