import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {slideInAnimation} from '@app-shared/animations/route-animations';
import {KeyStrokeService} from "@modules/base/key-stroke.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    animations: [slideInAnimation]
})
export class AppComponent {
    constructor(
	    private keyStrokeService: KeyStrokeService
    ) {
	    this.handleKeyboardEvent()
    }

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
	}

	handleKeyboardEvent() {
		document.addEventListener('keyup', (event) => {
			this.keyStrokeService.keyUp(event)
		})
		document.addEventListener('keydown', (event) => {
			this.keyStrokeService.keyDown(event)
		})
	}
}
