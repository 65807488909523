import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Observable} from "rxjs";
import {PusherService} from "@services/pusher.service";
import {SmakValidationError} from "@modules/schedule-maker/SmakValidationError";
import {SmakValidationType} from "@modules/schedule-maker/SmakValidationType";

@Injectable({
	providedIn: 'root'
})
export class SolverService {

	constructor(private http: HttpClient,
	            private pusherService: PusherService) {
	}

	getStatuses(): Observable<number[]> {
		return this.http.get<number[]>(environment.solverApiUrl + '/statuses')
	}

	solve(daily_schedule_ids: number[]) {
		return this.http.get<string>(environment.solverApiUrl + '/solve?daily_schedule_ids=' + daily_schedule_ids.join(','))
	}


	onSolvingCompletion() {
		return this.pusherService.getEvent('solver', 'solvingCompleted')
	}

	validate(dailyScheduleId: number) {
		return this.http.get<SmakValidationError[]>(environment.solverApiUrl + '/reports?daily_schedule_id=' + dailyScheduleId)
	}

	getReportTypes() {
		return this.http.get<SmakValidationType[]>(environment.solverApiUrl + '/report_types')
	}

	solveStudentSchedule(daily_schedule_ids: number[]) {
		return this.http.get<string>(environment.solverApiUrl + '/student_schedules/solve?daily_schedule_ids=' + daily_schedule_ids.join(','))
	}
}
