import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@environments/environment';

if (environment.environment == 'prod') {
  enableProdMode();
} else {
    console.log('Build in ' + environment.environment + ' mode!');
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
