import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Injectable} from "@angular/core";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((event: HttpErrorResponse) => this.tryStringifyRailsErrorMessage(event))
        );
    }

    constructor(private _snackBar: MatSnackBar) {
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            duration: 2000,
        });
    }

    private tryStringifyRailsErrorMessage(error: HttpErrorResponse) {
        if (error.error && error.status != 0) {
	        let messages = [];

	        for (let key in error.error) {
		        if (typeof error.error[key] == "string" && error.error[key] != '')
			        messages.push(key + ' : ' + error.error[key]);
	        }
	        if (messages.length > 0) {
		        let message = 'Some Errors occured. \n' + messages.join(', \n');
		        this.openSnackBar(message);
	        }
        }
        return throwError(error);
    }
}
