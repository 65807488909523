import {Injectable} from '@angular/core';
import {School} from '@models/School';
import {of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LaravelApiService} from '@services/LaravelApiService';

@Injectable({
    providedIn: 'root'
})
export class SchoolService extends LaravelApiService {
    private _current_school: School;
    private schools: School[];

    getAll() {
        if (this.schools)
            return of(this.schools);
        return this.getHttp<School[]>('/schools').pipe(
            tap(schools => this.schools = schools)
        );
    }

    get current_school(): School {
        return this._current_school;
    }

    set current_school(value: School) {
        this._current_school = value;
    }

    get current_school_id(): number {
        if (this.current_school)
            return this.current_school.id;
        return null;
    }
}
