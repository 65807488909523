<mat-form-field *ngIf="schedules" class="full-width">
    <mat-label>Select a week</mat-label>
    <mat-select
            [required]="required"
            [(ngModel)]="scheduleId"
            [compareWith]="compare" class="full-width">
        <ng-container *ngFor="let schedule of schedules">
            <mat-option [value]="schedule.id">
                Week of
                the {{schedule.date_begin | appDate }}
            </mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
<div *ngIf="!showUnpublished && schedules.length == 0">No schedule has been published yet for the current period. The
    school
    will send a message once it will be published.
</div>
