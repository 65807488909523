import {Persistable, Persists} from "@models/new-persist/Persistable";

const DEFINITION = [
    'current_page',
    'from',
    'last_page',
    'per_page',
    'to',
    'total',
    'data',
];

export class Paginator<T> extends Persistable<Paginator<T>> implements Persists {
    current_page: number = 0;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number = 0;
    data: T[] = [];

    constructor(per_page?: number) {
        super();
        this.per_page = per_page;
    }


    getDefinition(): string[] {
        return DEFINITION;
    }

    mapPersistables(mapper: (persistable: T) => T): Paginator<T> {
        if (this.data)
            this.data = this.data.map(item => mapper(item))
        return this;
    }

    toParams() {
        return {
            page: this.current_page + 1,
            per_page: this.per_page
        }
    }

    protected afterMap(json: Paginator<T>) {
        this.current_page--;
    }
}
