import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {Activity} from "@models/Activity";

export class ActivityFactory extends AbstractPersistableFactory<any> {
	make(json: Activity): Activity {
		let activity = new Activity();
		activity.map(json);
		return activity;
	}

}
