import {RepetitionStructure} from "@structures/repetition.structure";
import {Repetition} from "@models/repetition";
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";

export class RepetitionFactory extends AbstractPersistableFactory<any> {
	unavailabilityFactory: AbstractPersistableFactoryInterface;


	make(json: RepetitionStructure): Repetition {
		let rep = (new Repetition()).fromJson(json);

		if (json.main_unavailability)
			rep.main_unavailability = this.unavailabilityFactory.make(json.main_unavailability)
		return rep;
	}
}
