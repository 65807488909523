import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {Teacher} from "@models/teacher";
import {Role} from "@models/role";

export class TeacherFactory extends AbstractPersistableFactory<any> {
	make(json: any): Teacher {
		let teacher = (new Teacher()).fromJson(json);
		if (json.roles)
			teacher.roles = json.roles.map(value => (new Role()).fromJson(value));
		return teacher;
	}

}
