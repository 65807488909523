import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-hyperlink',
    templateUrl: './hyperlink.component.html',
    styleUrls: ['./hyperlink.component.css']
})
export class HyperlinkComponent implements OnInit {

    @Input() route: string;
    @Output() onRoutingDone: EventEmitter<string> = new EventEmitter();

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    navigate() {
        this.router.navigate([this.route]).then(
            () => this.onRoutingDone.emit(this.route)
        );
    }

    openNewWindow() {
        window.open(this.route, '_blank');
    }
}
