import {NgModule} from '@angular/core';
import {AlveoRouterDirective} from '@app-shared/routes/alveo-router.directive';

@NgModule({
    declarations: [
        AlveoRouterDirective
    ],
    exports: [
        AlveoRouterDirective
    ]
})
export class AlveoRouteProviderModule {

}
