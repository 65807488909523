import {User} from '@models/user';
import {UsersStructure} from '@structures/users.structure';
import {AbstractPersistableFactoryInterface} from "@factories/AbstractPersistableFactoryInterface";
import {AbstractPersistableFactory} from "@factories/AbstractPersistableFactory";
import {PersistableStructure} from "@structures/persistable.structure";

export class UserFactory extends AbstractPersistableFactory<any> {
	teacherFactory: AbstractPersistableFactoryInterface
	studentFactory: AbstractPersistableFactoryInterface

	make(json: UsersStructure): User {
		if (json.is_student)
			return this.studentFactory.make(json);
		if (json.is_teacher)
			return this.teacherFactory.make(json);
		return (new class extends User {
			structure: PersistableStructure;

			apiPayloadStructure() {
			}

			belongsToRole(roleId: number): boolean {
				return false;
			}
		}).fromJson(json)
	}
}
