import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
    selector: '[app-link]'
})
export class LinkDirective implements OnInit {

    constructor(private element: ElementRef) {
    }

    ngOnInit(): void {
        this.element.nativeElement.style.color = 'blue';
        this.element.nativeElement.style.textDecoration = 'underline';
        this.element.nativeElement.style.cursor = 'pointer';
    }
}
