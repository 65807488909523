import {Persistable} from "@models/new-persist/Persistable";
import {ScheduleTypeEnum} from "@modules/schedule-maker/schedule-type-enum";

const DEFINITION = [
	'name',
	'short_name',
	'is_archived',
	'is_activity_block',
	'is_group_activity'
]

export class Activity extends Persistable<Activity> {
	name: string;
	short_name: string;
	is_group_activity: boolean;
	is_archived: boolean;
	is_activity_block: boolean;

	getDefinition(): string[] {
		return DEFINITION;
	}

	shouldBeDisplayedForScheduleType(scheduleTypeEnum: ScheduleTypeEnum) {
		switch (scheduleTypeEnum) {
			case ScheduleTypeEnum.ClassSchedule:
				if (this.is_activity_block)
					return false;
				break;
			case ScheduleTypeEnum.StudentSchedule:
				if (!this.is_activity_block)
					return false;
				break;
		}
		return true;
	}
}
