import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {Schedule} from '@models/schedule';
import {map} from 'rxjs/operators';
import {ScheduleStructure} from '@structures/schedule.structure';
import {ScheduleFactory} from '@factories/schedule.factory';
import {ApiService} from '@services/ApiService';
import {ScheSortableColumns} from '@modules/schedules/sche-payload';
import {PersistableFactoryFactory} from '@factories/PersistableFactoryFactory';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService extends ApiService {
    persists = true;
    private scheduleFactory: ScheduleFactory;

    constructor(persistableFactoryFactory: PersistableFactoryFactory, http: HttpClient) {
        super(http);
		this.scheduleFactory = persistableFactoryFactory.scheduleFactory;
	}

	get(id: number): Observable<Schedule> {
		return this.getHttp('/schedules/' + id);
	}

	getAll(fetchOlds: boolean = false,
	       sort: { sort: ScheSortableColumns, direction: "asc" | "desc" } | {} | any = {
		       sort: ScheSortableColumns.DATE_BEGIN,
		       direction: "asc"
	       }): Observable<Schedule[]> {
		sort.fetch_old_schedules = fetchOlds
		return this.getHttp('/schedules', {
			params: sort
		});
	}

	publish(scheduleId: number): Observable<boolean> {
		return this.postHttp<boolean>('/schedules/publish/' + scheduleId, {}, false);
	}

	delete(id: number): Observable<number> {
		return this.http.delete<number>(environment.apiUrl + '/schedules/' + id);
	}

	add(schedule: Schedule): Observable<Schedule> {
		return this.http.post<ScheduleStructure>(environment.apiUrl + '/schedules', schedule.json()).pipe(
            map((schedule) => this.scheduleFactory.produce(schedule))
        );
    }

    update(schedule: Schedule): Observable<Schedule> {
        return this.http.put<ScheduleStructure>(environment.apiUrl + '/schedules/' + schedule.id, schedule.json()).pipe(
            map((schedule) => this.scheduleFactory.produce(schedule))
        );
    }

    export(scheduleId: number, school_id: number): Observable<any> {
        return this.getHttp('/schedules/' + scheduleId + '/export?school_id=' + school_id, null, false);
    }

    getExportUrl(scheduleId: number, jobId: string) {
        return this.getHttp<{
            status?: string;
            url?: string
        }>('/schedules/' + scheduleId + '/export_url?job_id=' + jobId, null, false);
    }

    public map(json: any): any {
		return this.scheduleFactory.make(json)
	}
}
