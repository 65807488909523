import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '@models/user';
import {Observable} from 'rxjs';
import {SessionsService} from '@services/sessions.service';
import {UserFactory} from '@factories/user.factory';
import {PersistableFactoryFactory} from '@factories/PersistableFactoryFactory';
import {LaravelApiService} from '@services/LaravelApiService';

@Injectable({
    providedIn: 'root'
})
export class UserService extends LaravelApiService {
    private userFactory: UserFactory;
    persists = true

    constructor(http: HttpClient,
                private sessionsService: SessionsService,
                persistableFactoryFactory: PersistableFactoryFactory) {
        super(http);
        this.userFactory = persistableFactoryFactory.userFactory;
    }

    getUser(id): Observable<User> {
        return this.getHttp('/users/' + id);
    }

    public map(json: any): any {
        return this.userFactory.make(json)
    }

    update(user: User): Observable<User> {
        return this.putHttp('/users/' + user.id, user.apiPayloadStructure());
    }
}
