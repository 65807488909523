import {FormatableDate} from './FormatableDate';
import * as moment from 'moment';
import {Moment} from 'moment';

export class DateFactory {

    public static produceDate(value: string | Date): FormatableDate {
        //example 2019-03-04T18:35:43.029Z
        if (value) {
            return new FormatableDate(moment.utc(value));
        }
        return this.handleDateFormatErrors(value);
    }

    public static handleDateFormatErrors(value: any) {
        console.error('Could not parse moment: ' + value);
        return null;
    }

    public static produceFromDatePart(date: string, time: string): Moment {
        let momentDate = moment.utc(date);
        let momentTime = moment.utc(time, 'hh:mm a');
        momentDate.set({
            hour: momentTime.hours(),
            minute: momentTime.minutes(),
            second: 0,
            millisecond: 0
        });
        return momentDate;
    }
}
